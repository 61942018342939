import * as React from 'react'
import Layout from '../components/Layout'

import BrandPortfolio from "../components/BrandPortfolio"

import heroImg from "../images/adjmi/home/black-brand-banner.jpg";

import tw from 'twin.macro' // eslint-disable-line


const BrandsPage = () => {

  return (
    <Layout>

      <img src={heroImg} alt="Hero Header" />


      <div tw='py-7'>
        <BrandPortfolio />
      </div>

    </Layout>
  )
}

export default BrandsPage
